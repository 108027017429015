var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents",attrs:{"id":"contents"}},[_c('div',{staticClass:"inner-sm"},[_c('section',{staticClass:"board-view-section"},[_c('div',{staticClass:"board-view"},[_c('div',{staticClass:"view-header"},[_c('h5',[_vm._v(_vm._s(_vm.eventInfo.name))]),_c('div',{staticClass:"header-info"},[_c('ul',[_c('li',{staticClass:"date"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getDateString(_vm.eventInfo.startDate, _vm.eventInfo.endDate))+" ")])]),[_c('li',{staticClass:"v-bar"},[_vm._v(_vm._s(_vm.eventInfo.placeName || _vm.eventInfo.placeDetail))])],_c('li',{staticClass:"v-bar"},[_vm._v(_vm._s(_vm.getApplyUser(_vm.eventInfo.targets)))])],2),_c('ul',{staticClass:"writer"},[_c('li',[_vm._v(_vm._s(_vm.eventInfo.adminName))]),_c('li',{staticClass:"v-bar"},[_vm._v(_vm._s(_vm.format.date(_vm.eventInfo.insertDate)))])])])]),_c('div',{staticClass:"view-body"},[_c('BaseViewer',{attrs:{"value":_vm.editorContent}}),(_vm.eventInfo.precaution || _vm.eventInfo.precaution2 || _vm.eventInfo.precaution3)?_c('div',{staticClass:"precautions-section grey-box"},[_c('ul',{staticClass:"bullet-circle-list"},[(_vm.eventInfo.precaution)?_c('li',[_vm._v(_vm._s(_vm.eventInfo.precaution))]):_vm._e(),(_vm.eventInfo.precaution2)?_c('li',[_vm._v(_vm._s(_vm.eventInfo.precaution2))]):_vm._e(),(_vm.eventInfo.precaution3)?_c('li',[_vm._v(_vm._s(_vm.eventInfo.precaution3))]):_vm._e()])]):_vm._e()],1)])]),_c('div',{staticClass:"paging-area board"},[_c('div',{staticClass:"btn-wrap"},[(_vm.eventInfo.externalUrl)?_c('a',{attrs:{"href":_vm.eventInfo.externalUrl,"target":"_blank","title":"새창"}},[_c('button',{staticClass:"btn-basic",class:{ disabled: _vm.invaildedDate },attrs:{"disabled":_vm.invaildedDate}},[_vm._v(_vm._s(_vm.invaildedDate ? _vm.$t('content.event.Event.txt14') : _vm.$t('content.event.Event.txt15')))])]):_c('button',{class:{ disabled: _vm.applicated.invalided,
            'btn-ghost': _vm.applicated.isBtnGhost,
            'btn-basic': !_vm.applicated.isBtnGhost },attrs:{"disabled":_vm.applicated.invalided},on:{"click":_vm.application}},[_vm._v(_vm._s(_vm.applicated.text))])]),_c('ul',{staticClass:"paging"},[_c('li',[_c('a',{staticClass:"back",attrs:{"href":"#"},on:{"click":_vm.historyBack}},[_vm._v("Back")])]),_c('li',[_c('router-link',{staticClass:"prev",class:{disabled: !_vm.eventInfo.prev},attrs:{"to":{ name: 'EventDetail'
              , params: { eventNo: _vm.eventInfo.prev }
              , query: { Date: this.eventDate} },"tag":"a"}},[_vm._v("Prev")])],1),_c('li',{staticClass:"v-bar"},[_c('router-link',{staticClass:"next",class:{disabled: !_vm.eventInfo.next},attrs:{"to":{ name: 'EventDetail'
              , params: { eventNo: _vm.eventInfo.next}
              , query: { Date: this.eventDate} },"tag":"a"}},[_vm._v("Next")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }