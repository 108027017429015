<template>
  <quillEditor
    :content="value"
    :options="options"
    :disabled="true"
  />
</template>

<script>
// https://github.com/surmon-china/vue-quill-editor
import { quillEditor } from 'vue-quill-editor';

/**
 * 기본 뷰어 컴포넌트
 * @see https://github.surmon.me/vue-quill-editor/
 * @see https://quilljs.com/
 */
export default {
  name: 'BaseViewer',
  components: {
    quillEditor,
  },
  props: {
    /**
     * 컨텐츠(HTML)
     * @model
     */
    value: {
      type: String,
    },
  },
  data() {
    return {
      // https://quilljs.com/docs/configuration/
      options: {
        placeholder: '',
        theme: 'bubble',
      },
    };
  },
};
</script>

<style scoped>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';

>>> .ql-toolbar { display: none; }
>>> .ql-container { border: 0 !important; font-size: 16px; }
>>> .ql-editor img { margin: 0 auto; }
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<BaseViewer :value="'<p>가나다라마바사</p><p><strong>ABCDE</strong></p><p>1234</p>'"></BaseViewer>
```
</docs>
